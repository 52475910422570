@use '/src/styles/variables' as var;

.custom-card {
  box-shadow: none !important;
  margin-bottom: 1rem;

  .title-section {
    padding: 0.5rem 1rem !important;
    justify-content: flex-start !important;
  }

  .ant-card-body {
    .description {
      padding: 1rem !important;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      gap: 1rem;
    }
  }

  .ant-tag {
    word-break: break-all;
    white-space: pre-line;
    padding: 0.5rem;
    background-color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
      0 1px 6px -1px rgba(0, 0, 0, 0.02), 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  }

  .ant-tag-checkable-checked {
    background-color: var.$primary-color;
    color: var.$natural-0;
  }
}
