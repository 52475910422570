$primary-color: #005bd4;
$secondary-color: #273e53;

$natural-1: #101213;
$natural-2: #272b30;
$natural-3: #4f575e;
$natural-4: #6a7178;
$natural-5: #adb5bd;
$natural-6: #ced4da;
$natural-7: #dee2e6;
$natural-8: #e9ecef;
$natural-9: #f1f3f5;
$natural-10: #f6f6f6;
$natural-0: #ffff;

$info-base: #017aad;
$info-light: #e5f5fc;
$success-base: #29823b;
$success-light: #eaf3eb;
$warning-base: #e9a100;
$warning-light: #fdf6e5;
$error-base: #dc2020;
$error-light: #fceaea;

$w-normal: 400;
$w-500: 500;
$w-600: 600;
$w-700: 700;

$text-sm: 12px;
$text-md: 14px;
$text-lg: 16px;

$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1600px;
