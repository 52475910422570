.main-content-view {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
  width: 100%;

  @media screen and (min-width: 768px) {
    max-width: 1024px;
    margin: 0 auto;
  }
}
